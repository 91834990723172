var quizQuestions = [
    {
        question: "What is your dream holiday?",
        answers: [
            {
                type: "Tempranillo",
                content: "Camino de Santiago ",
                image: "camino.png"
            },
            {
                type: "Barolo",
                content: "Dolomites",
                image: "dolomites.png"
            },
            {
                type: "Albarino",
                content: "Canary Islands ",
                image: "canaryislands.png"
            },
            {
                type: "Etna",
                content: "Sicily and its Greek temples",
                image: "sicily.png"
            },
            {
                type: "Sherry",
                content: "Granada and the Arabic wonders",
                image: "granada.png"
            },
            {
                type: "Franciacorta",
                content: "Milan",
                image: "milano.png"
            },
        ]
    },
    {
        question: "If money was no object which dish will you choose at the restaurant?",
        answers: [
            {
                type: "Tempranillo",
                content: "Lamb Chops",
                image: "lambchops.png"
            },
            {
                type: "Barolo",
                content: "Pasta with truffle ",
                image: "pastawithtruffle.png"
            },
            {
                type: "Albarino",
                content: "Pulpo a la gallega ",
                image: "pulpoalagalega.png"
            },
            {
                type: "Etna",
                content: "Swordfish Steaks with olives and Capers",
                image: "swordfish.png"
            },
            {
                type: "Sherry",
                content: "Paella with Lobster",
                image: "paellawithlobster.png"
            },
            {
                type: "Franciacorta",
                content: "Ravioli with pumpkin and walnuts",
                image: "ravioli.png"
            },
        ]
    },
    {
        question: "Which dessert would you choose?",
        answers: [
            {
                type: "Tempranillo",
                content: "Pear in red wine ",
                image: "pears.png"
            },
            {
                type: "Barolo",
                content: "Baci di dama ",
                image: "bacididama.png"
            },
            {
                type: "Albarino",
                content: "Catalan Cream ",
                image: "cremacatalana.png"
            },
            {
                type: "Etna",
                content: "Ice Cream ",
                image: "icecream.png"
            },
            {
                type: "Sherry",
                content: "Churros",
                image: "churros.png"
            },
            {
                type: "Franciacorta",
                content: "Tiramisù",
                image: "tiramisu.png"
            },
        ]
    },
    {
        question: "After dinner what do you like to do?",
        answers: [
            {
                type: "Tempranillo",
                content: "Football match ",
                image: "footballmatch.png"
            },
            {
                type: "Barolo",
                content: "Cinema",
                image: "cinema.png"
            },
            {
                type: "Albarino",
                content: "A walk by the sea ",
                image: "strollbythesea.png"
            },
            {
                type: "Etna",
                content: "Chat with friends",
                image: "chatwithfriends.png"
            },
            {
                type: "Sherry",
                content: "Flamenco ",
                image: "flamenco.png"
            },
            {
                type: "Franciacorta",
                content: "Opera ad Arena",
                image: "opera.png"
            },
        ]
    },
    {
        question: "What food are you snacking on?",
        answers: [
            {
                type: "Tempranillo",
                content: "Cherries",
                image: "cherries.png"
            },
            {
                type: "Barolo",
                content: "Chocolates ",
                image: "chocolate.png"
            },
            {
                type: "Albarino",
                content: "Apricot ",
                image: "apricots.png"
            },
            {
                type: "Etna",
                content: "Oranges",
                image: "oranges.png"
            },
            {
                type: "Sherry",
                content: "Almonds",
                image: "almonds.png"
            },
            {
                type: "Franciacorta",
                content: "Peaches",
                image: "peaches.png"
            },
        ]
    },
    {
        question: "What sentence would you like to learn for your holiday?",
        answers: [
            {
                type: "Tempranillo",
                content: "Te quiero",
                image: "tequiero.png"
            },
            {
                type: "Barolo",
                content: "Buongiorno! ",
                image: "buongiorno.png"
            },
            {
                type: "Albarino",
                content: "Muchas gracias ",
                image: "muchasgracias.png"
            },
            {
                type: "Etna",
                content: "Mamma mia ",
                image: "mammamia.png"
            },
            {
                type: "Sherry",
                content: "Hola",
                image: "hola.png"
            },
            {
                type: "Franciacorta",
                content: "Caffè o Cappuccino",
                image: "caffèocappuccino.png"
            },
        ]
    },

];




var resultDescriptions = [
    { Tempranillo: "A name, a program: Tempranillo comes from temprano, and it means 'early maturation,' in other words, you have the ability to grow fast. The autonomy you have developed brought you to Spain, after a long journey from France, precisely the path of Santiago de Compostela. It only took you a few days to get used to the Latin temperatures: your skin gets easily tanned, and you find your comfort zone both in the cooler areas like Rioja and the heat of the Portuguese region of Alentejo. In fact, every now and then you like to make an appearance in Portugal, Argentina and Italy, where your Latin-lover skills (literally) are equally admired. Turning to your weak points, you're not too sweet, but that can also be an advantage: you're acidic in just the right way. After your pilgrimage you decided that it was time to abandon solitude and let yourself be accompanied by other 'characters', among your best friends we find Garnacha. What can I say, your teenage kicks have with time become sweeter, indeed, you are enveloped in the gentle scent of vanilla. Is that old age? Could it be the years you spent in your humble, barrique-wooded home? Maybe, but it doesn't matter: we like you like that!" },


    { Barolo: "Hey, is your name Google? Because you have everything we are looking for: raspberries, blackcurrants, kirsch, wilted flowers, spices, leather, green pepper, aniseed, nutmeg and liquorice, but also products related to the territory such as hazelnuts and truffles. Perhaps it is appropriate to admit that you were born with your shirt on, in a castle surrounded by princely green and sunny hills, and by nobles just like your best friend Giulia, Marquis of Barolo. Maybe that's why they also call you the 'king of wines' and 'wine of kings'. Despite your good fortune, you have maintained over time the humility and gratitude typical of a true leader, giving half of your fame to the village and the citizens who have made you what you are. That medieval 'castle of Barolo' is now a world famous image of Italy. But coming back to you (we know that your ego is gigantic), we feel we can define you as the classic 'all in one piece', a 100% Nebbiolo, but only after the completion of your 3 years of life, precisely your 38 months. You started talking late, but nobody is perfect, and the embarrassing moment has come when you browse through the family album. Let's pay attention to the caption of the first photo, the first complete sentence you said coming out of the wooden cradle where you slept for 18 months: 'can you pass me a glass? Time to move house!" },


    {
        Albarino: "Many stories are told about you, the best known is that you were raised by the Spanish river Umia, and lived there since the Middle Ages. Is it true? We'll never know, but we know that, like a river, you like to wander. Your favorite destination is Galicia and the Rìas Baixas (Spain), but sometimes you also appear in Cantabria, Castile and Leon, Catalonia, and in 1996 you  discovered that the roller skates of Miami beach are a great fun, but the temperate temperatures of the Californian hinterland were even more appealing. You're tiny, pale, but you have a strong heart and a vigorous physique. If you had to choose between meat of fish there is no question, fish every time. Your freshness and lightness of mind combined with the salinity of your skin after your daily work-out goes perfectly with the taste. In your best dress, however, you wear apricot, and over the years no one has been able to resist this scent: from 14 suitors you have reached 142. Imagine if they were owners of famous wineries…"
    },
    { Etna: "You are a volcano, in name and in fact, although your physiognomy is more reminiscent of an freestyle Albarello. Born and raised on the slopes of Etna, trekking, adventure and strong emotions are the order of the day. A wild child of the Sixties in 1968 your name was the first registered as a PDO in the Italian region of Sicily, immediately increasing its fame. You should be proud of it! You are tall, very tall, almost as tall as a Celtics Basketball player, that is 1200 meters, more or less! Must be the low temperatures, they say that 'down in the north' you eat a heartily and well, and it is the cold of the mountain that drives your appetite. You live with natural light but also reflected light, you're practically di-wine, you're like a Hollywood star who is down to earth. You can choose what to wear during rehearsals on set: whether it's a light outfit or a dark one, you're beautiful and good in any case." },
    { Sherry: "If you were a movie title, you'd be 'Gone with the Wind,' as long as it's hot. You're a traveller, but you always show your best at home in Spain. You take your name from a Phoenician city, but over the years you have made friends with many Europeans who have baptized you in different ways: Jerez for the Spanish, Sherry for the English, Xepec for the Russians, Ceretanum from Latin, and you call yourself Pedro Ximenes (for friends 'PX') when you feel dehydrated. They all know you, because you have a personality and way of life is quite sought-after and different from all your peers, especially because of your growth process, your fortified texture and your ageing. You're not an early riser at all: until a few years ago your typical day started lying on the mats, while now you like nothing more than to nod off in tree, until someone comes to wake you up with the arrival of autumn. It keeps you a little buzzing for up to 8 days, and then you're in top form. Besides, your motto is 'what doesn't kill, fortifies.' As you get older you're not the one who watches the construction sites, but rather you prefer to stay at home and rest, and even for that you have your own way: on a wooden bunk bed, you go from the highest to the lowest one that you like to call Soleras, because you're almost in contact with the ground. Despite the many names at your disposal, you have given your soul another one: Palomino, we must admit that it suits you... 90%. " },


    { Franciacorta: "Elegant, truly sparkling and a lover of worldly life: for your fellow Italians you are like France, but shorter. You are always ready to toast, to yours and anyone else's, especially since you won the DOCG medal in 2011 in your hometown, Brescia. Despite your versatile personality, you also express your preferences: if you are offered a trip to a damp place, with the risk of frost, not very sunny and too flat, you refuse without thinking too much about it. That's why you prefer the air of home and surroundings: in the hills it's fine unless it's below 550m, water is fine unless it's a real rescue irrigation, and streams ok, but at least 10 meters from you. (we said ... 'versatile?') In short, a real princess, invited to every aperitif or dinner, as long as there is fish on the table ... you are pro-Vegetarian, because now it's fashionable like that. Friends also know you as Pinot Noir, Pinot Blanc and Chardonnay, all three together, because a personality is not enough to describe you. Your strong point? Your method to win everyone over? Classicism: nobody beats you in this." },

];


export { quizQuestions, resultDescriptions };
