import React, { Component } from 'react';
import { quizQuestions, resultDescriptions } from './quizQuestions';
import Quiz from './components/Quiz';
import Result from './components/Result';
import logo from './svg/logo.svg';
// import './App.css';

class QuizComponent extends Component {
   constructor(props) {
      super(props);

      this.state = {
         counter: 0,
         questionId: 1,
         question: '',
         answerOptions: [],
         answer: '',
         answersCount: {},
         result: '',
         description: ''
      };

      this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
   }

   componentDidMount() {
      const shuffledAnswerOptions = quizQuestions.map(question =>
         this.shuffleArray(question.answers)
      );
      this.setState({
         question: quizQuestions[0].question,
         answerOptions: shuffledAnswerOptions[0]
      });
   }

   shuffleArray(array) {
      var currentIndex = array.length,
         temporaryValue,
         randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
         // Pick a remaining element...
         randomIndex = Math.floor(Math.random() * currentIndex);
         currentIndex -= 1;

         // And swap it with the current element.
         temporaryValue = array[currentIndex];
         array[currentIndex] = array[randomIndex];
         array[randomIndex] = temporaryValue;
      }

      return array;
   }

   handleAnswerSelected(event) {
      this.setUserAnswer(event.currentTarget.value);

      if (this.state.questionId < quizQuestions.length) {
         setTimeout(() => this.setNextQuestion(), 300);
      } else {
         setTimeout(() => this.setResults(this.getResults()), 300);
      }
   }

   setUserAnswer(answer) {
      this.setState((state, props) => ({
         answersCount: {
            ...state.answersCount,
            [answer]: (state.answersCount[answer] || 0) + 1
         },
         answer: answer
      }));
   }

   setNextQuestion() {
      const counter = this.state.counter + 1;
      const questionId = this.state.questionId + 1;

      this.setState({
         counter: counter,
         questionId: questionId,
         question: quizQuestions[counter].question,
         answerOptions: quizQuestions[counter].answers,
         answer: ''
      });
   }

   getResults() {
      const answersCount = this.state.answersCount;

      const answersCountKeys = Object.keys(answersCount);
      const answersCountValues = answersCountKeys.map(key => answersCount[key]);
      const maxAnswerCount = Math.max.apply(null, answersCountValues);

      return answersCountKeys.filter(key => answersCount[key] === maxAnswerCount);
   }
   getResultsDescription(result) {
      var result = result.toString();
      console.log(result)
      if (result === "Tempranillo") {
         return resultDescriptions[0];
      }
      if (result === "Barolo") {

         return resultDescriptions[1];
      }
      if (result === "Albarino") {

         return resultDescriptions[2];
      }
      if (result === "Etna") {

         return resultDescriptions[3];
      }
      if (result === "Sherry") {
         return resultDescriptions[4];
      } if (result === "Franciacorta") {

         return resultDescriptions[5];
      }
      return "";

   }
   setResults(result) {

      var desc = this.getResultsDescription(result[0]);
      if (result.length === 1) {
         this.setState({ result: result[0], description: desc });
      } else {
         this.setState({ result: result[0], description: desc });// temporary solution
      }
   }


   renderQuiz() {
      return (
         <Quiz
            answer={this.state.answer}
            answerOptions={this.state.answerOptions}
            questionId={this.state.questionId}
            question={this.state.question}
            questionTotal={quizQuestions.length}
            onAnswerSelected={this.handleAnswerSelected}
         />
      );
   }

   renderResult() {
      return <Result desc={this.state.description} quizResult={this.state.result} />;
   }

   render() {
      return (
         <div className="quizContainer">
            {this.state.result ? this.renderResult() : this.renderQuiz()}
         </div>
      );
   }
}

export default QuizComponent;