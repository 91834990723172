import React from 'react';
import SlotMachine from './react-slot-machine'
import { reels } from './reels'


const options = {
   reelHeight: 1200,
   reelWidth: 200,
   reelOffset: 20,
   slotYAxis: 0,
   animSpeed: 1000,
   rngFunc: function () {
      return Math.random();
   },
};
//1 to 4


class NgoSlotMachine extends React.Component {
   constructor() {
      super();
      this.state = {
         spinResult: "",
         spinResultUrl: "",
         play: false,
      };
      this.callback = this.callback.bind(this);
      this.randomizeReel = this.randomizeReel.bind(this);
   }

   playEvent() {
      this.setState({
         play: !this.state.play,
         spinResult: "",
         spinResultUrl: "",
      });
   }
   randomizeReel() {
      var rand = 0;
      if (reels.length > 1) {
         var rand = Math.round(Math.random() * reels.length) - 1;
      }
      return reels[rand]
   }

   callback(payLine) {
      if (payLine[0].title == payLine[2].country && payLine[1].title == payLine[2].region) {
         // console.log("payLine[0].title", payLine[0].title, "payLine[2].country", payLine[2].country, "payLine[2].region", payLine[2].region, "payLine[1].title", payLine[1].title);
         console.log("jackpot");
         setTimeout(() => this.setState({
            spinResult: ` ${payLine[2].title} - ${payLine[2].region} - ${payLine[2].country}`,
            spinResultUrl: payLine[2].url
         }), 3600)
      } else
         setTimeout(() => this.setState({
            spinResult: `No luck! try again!`,
         }), 3600)

   }
   render() {
      return (
         <React.Fragment>
            <button id="play-button" className="arca18" onClick={() => this.playEvent()}>PLAY</button>
            {this.state.spinResult ? <p className="arca58 quizResult"> <a href={this.state.spinResultUrl}> {this.state.spinResult} </a>   </p> :
               <p
                  className="quizResult">Your next wine destination is...
               </p>}
            <SlotMachine callback={this.callback} reels={this.randomizeReel()} play={this.state.play} options={options} />
         </React.Fragment>
      );
   }
};


export default NgoSlotMachine