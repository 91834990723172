import strip1 from './reel-strip1.png'
import strip2 from './reel-strip2.png'
import strip3 from './reel-strip3.png'
import strip4 from './reel-strip4.png'
import reel1strip2 from './reel1-strip2.png'

const reels1 = [
   {
      imageSrc: strip1,
      symbols: [
         {
            title: 'Italy',
            position: 100,
            weight: 6
         },
         {
            title: 'Spain',
            position: 300,
            weight: 1
         },
         {
            title: 'Italy',
            position: 500,
            weight: 5
         },
         {
            title: 'Spain',
            position: 700,
            weight: 1
         },
         {
            title: 'Italy',
            position: 900,
            weight: 6
         },
         {
            title: 'Spain',
            position: 1100,
            weight: 1
         }
      ]
   },
   {
      imageSrc: reel1strip2,
      symbols: [
         {
            title: 'Abruzzo',
            position: 100,
            weight: 6,
            country: 'Italy'
         },
         {
            title: 'Sardegna',
            position: 300,
            weight: 5,
            country: 'Italy'
         },
         {
            title: 'Calabria',
            position: 500,
            weight: 3,
            country: 'Italy'
         },
         {
            title: 'Abruzzo',
            position: 700,
            weight: 5,
            country: 'Italy'
         },
         {
            title: 'Sardegna',
            position: 900,
            weight: 2,
            country: 'Italy'
         },
         {
            title: 'Calabria',
            position: 1100,
            weight: 1,
            country: 'Italy'
         }
      ]
   },
   {
      imageSrc: strip3,
      symbols: [
         {
            title: "Montepulciano d'Abruzzo",
            url: 'https://canada.nativegrapeodyssey.com/valle-daosta-doc/',
            position: 100,
            weight: 4,
            region: 'Abruzzo',
            country: 'Italy'
         },
         {
            title: 'Vermentino di Gallura',
            url: 'https://canada.nativegrapeodyssey.com/montefalco-sagrantino/',
            position: 300,
            weight: 1,
            region: 'Calabria',
            country: 'Italy'
         },
         {
            title: 'Greco di Bianco',
            position: 500,
            url: 'https://canada.nativegrapeodyssey.com/torgiano-rosso-riserva/',
            weight: 6,
            region: 'Sardegna',
            country: 'Italy'
         },
         {
            title: "Trebbianno  d'Abruzzo",
            position: 700,
            url: 'https://canada.nativegrapeodyssey.com/narni/',
            weight: 5,
            region: 'Abruzzo',
            country: 'Italy'
         },
         {
            title: 'Vermentino di Sardegna',
            position: 900,
            url: 'https://canada.nativegrapeodyssey.com/vermentino-di-gallura/',
            weight: 3,
            region: 'Abruzzo',
            country: 'Italy'
         },
         {
            title: 'Terre di Cosenza',
            position: 1100,
            url: 'https://canada.nativegrapeodyssey.com/amarone-della-valpolicella/',
            weight: 2,
            region: 'Abruzzo',
            country: 'Italy'
         }
      ]
   }

];

const reels2 = [
   {
      imageSrc: strip1,
      symbols: [
         {
            title: 'Italy',
            position: 100,
            weight: 6
         },
         {
            title: 'Italy',
            position: 300,
            weight: 6
         },
         {
            title: 'Spain',
            position: 500,
            weight: 1
         },
         {
            title: 'Italy',
            position: 700,
            weight: 5
         },
         {
            title: 'Spain',
            position: 900,
            weight: 1
         },
         {
            title: 'Italy',
            position: 1100,
            weight: 5
         }
      ]
   },
   {
      imageSrc: strip2,
      symbols: [
         {
            title: 'Piemonte',
            position: 100,
            weight: 6,
            country: 'Italy'
         },
         {
            title: 'Veneto',
            position: 300,
            weight: 1,
            country: 'Italy'
         },
         {
            title: 'Marche',
            position: 500,
            weight: 1,
            country: 'Italy'
         },
         {
            title: 'Piemonte',
            position: 700,
            weight: 5,
            country: 'Italy'
         },
         {
            title: 'Liguria',
            position: 900,
            weight: 4,
            country: 'Italy'
         },
         {
            title: 'Sicilia',
            position: 1100,
            weight: 5,
            country: 'Italy'
         }
      ]
   },

   {
      imageSrc: strip4,
      symbols: [
         {
            title: 'Dogliani',
            position: 100,
            url: 'https://canada.nativegrapeodyssey.com/dogliani/',
            weight: 6,
            region: 'Piemonte',
            country: 'Italy',
         },
         {
            title: "Valle d\'Aosta",
            position: 300,
            url: 'https://canada.nativegrapeodyssey.com/valle-daosta-doc/',
            weight: 1,
            region: "Valle d\'Aosta",
            country: 'Italy',
         },

         {
            title: 'Barolo',
            position: 500,
            url: 'https://canada.nativegrapeodyssey.com/barolo/',
            weight: 5,
            region: 'Piemonte',
            country: 'Italy',
         },
         {
            title: 'Chianti',
            position: 700,
            url: 'https://canada.nativegrapeodyssey.com/chianti/',
            weight: 3,
            region: 'Toscana',
            country: 'Italy',
         },

         {
            title: 'Etna',
            position: 900,
            url: 'https://canada.nativegrapeodyssey.com/etna/',
            weight: 5,
            region: 'Sicilia',
            country: 'Italy',
         },
         {
            title: 'Colli di Luni',
            position: 1100,
            url: 'https://canada.nativegrapeodyssey.com/colli-di-luni/',
            weight: 5,
            region: 'Liguria',
            country: 'Italy',
         },

      ]
   }
];


const reels = [reels2]


export { reels };